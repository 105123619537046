import React, { useContext, useRef, useEffect, useMemo, useCallback } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import * as PIXI from 'pixi.js-legacy';
import { Context } from '@/context';
import { PixiContext } from '@/context/pixi';
import { Context as LayerContext } from '@/context/layer';
import { Scrollbar } from '@/context/scrollbar';
import { useResize } from '@/package/ReactHandlers';
import { loadPixiTexture } from '@/utils';
import { Y_MARGIN } from '@/constants';
import useWillMount from '@/hooks/useWillMount';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import useScrollbar from '@/hooks/useScrollbar';
import useBreakpoints from '@/hooks/useBreakpoints';
import styles from './Pixi.module.css';

const Pixi = ({
  data,
  children,
}) => {
  const wrapperRef = useRef();
  const appRef = useRef();
  const mainContainerRef = useRef();
  const dispContainerRef = useRef();
  const notMounted = useRef(false);
  const { scroll, scrollTo } = useContext(Scrollbar);
  const { isMenuActive } = useContext(LayerContext);
  const { setIsPixiLoaderComplete, isPixiLoaderComplete, setSiteHeight, pixiResources, sectionFromMenu, isIntroCompleted, isIdle, language } = useContext(Context);
  const imagesRef = useRef([]);
  const appHeight = useRef(0);
  const autoScroll = useRef(0);
  const { screen } = useBreakpoints();

  const pixiConfig = useMemo(() => {
    const ua = global.navigator ? global.navigator.userAgent : '';
    const huaweip9 = ua.includes('HuaweiALE-L21');

    const config = {
      resolution: global.devicePixelRatio
        ? Math.min(2, global.devicePixelRatio)
        : 1,
      antialias: true,
      autoResize: true,
      width: global.innerWidth,
      height: global.innerHeight,
      transparent: true,
    };

    if (huaweip9) {
      config.preserveDrawingBuffer = true;
      config.resolution = 1;
    }
    return config;
  });

  const setHeight = useCallback(() => {
    if (mainContainerRef.current) appHeight.current = mainContainerRef.current.children.reduce((a, b) => a + b.height, 0) + (Y_MARGIN[screen] * mainContainerRef.current.children.length) + 250;
    setSiteHeight(appHeight.current);
    if (Math.abs(scroll.current) > appHeight.current) {
      scrollTo(appHeight.current - global.innerHeight);
    }
  }, [screen]);

  const onResize = (width, height) => {
    appRef.current.renderer.view.style.width = `${width}px`;
    appRef.current.renderer.view.style.height = `${height}px`;
    appRef.current.renderer.resize(width, height);
  };

  useResize(() => {
    onResize(global.innerWidth, global.innerHeight);
    setHeight();
  }, [screen]);

  useWillMount(() => {
    if (typeof window !== 'undefined') {
      appRef.current = new PIXI.Application(pixiConfig);
    } else {
      notMounted.current = true;
    }
  });

  useScrollbar(({ y }) => {
    if (mainContainerRef.current) mainContainerRef.current.y = -y;
    if (
      isIdle
      && isIntroCompleted
      && !(Math.abs(y) > ((appHeight.current - global.innerHeight) - 50))
      && y < 200
    ) {
      autoScroll.current += 0.1;
      scrollTo(y + autoScroll.current);
    }
  }, [isIdle, isIntroCompleted]);

  const loadTexture = async (urls) => {
    await loadPixiTexture({
      src: urls,
      complete: (e) => {
        pixiResources.current = Object.assign(pixiResources.current, e.resources);
        setIsPixiLoaderComplete(true);
      },
    });
  };

  useEffect(() => {
    if (!notMounted.current) {
      wrapperRef.current.appendChild(appRef.current.view);
      data.map(chapter => chapter.node[language === 'it' ? 'gallery' : 'galleryEn'].map(item => imagesRef.current.push(item.url)));

      mainContainerRef.current = new PIXI.Container();
      mainContainerRef.current.name = 'MAIN_CONTAINER';
      dispContainerRef.current = new PIXI.Container();
      dispContainerRef.current.name = 'DISP_CONTAINER';

      appRef.current.stage.addChild(mainContainerRef.current);
      appRef.current.stage.addChild(dispContainerRef.current);
    }
  }, []);

  useUpdateEffect(() => {
    if (
      !isMenuActive
      && sectionFromMenu !== undefined
      && mainContainerRef.current.children[sectionFromMenu]
    ) {
      const y = mainContainerRef.current.children[sectionFromMenu].y - Y_MARGIN[screen] + 5;
      scrollTo(y);
    }
  }, [isMenuActive, sectionFromMenu, screen]);

  useEffect(() => {
    loadTexture(imagesRef.current);
  }, [imagesRef.current]);

  useUpdateEffect(() => {
    setHeight();
  }, [isPixiLoaderComplete]);

  useEffect(() => {
    anime({
      targets: wrapperRef.current,
      easing: 'easeInOutQuad',
      translateY: isIntroCompleted ? 0 : '200px',
      opacity: isIntroCompleted ? 1 : 0,
      duration: 500,
    });
  }, [isIntroCompleted]);

  return !notMounted.current ? (
    <PixiContext.Provider value={{
      app: appRef.current,
      renderer: appRef.current.renderer,
      stage: appRef.current.stage,
      mainContainer: mainContainerRef.current,
      dispContainer: dispContainerRef.current,
    }}
    >
      <div
        ref={wrapperRef}
        className={styles.wrapper}
      >
        {children}
      </div>
    </PixiContext.Provider>
  ) : null;
};

Pixi.propTypes = { data: PropTypes.array.isRequired };

export default Pixi;
