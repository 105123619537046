import React, { useState, useCallback, useRef } from 'react';

const Context = React.createContext({});
const {
  Provider,
  Consumer,
} = Context;

const LayerContext = ({ children }) => {
  const initialState = [
    {
      id: 'menu',
      isActive: false,
    },
    {
      id: 'gallery',
      isActive: false,
    },
    {
      id: 'intro',
      isActive: true,
    },
    {
      id: 'about',
      isActive: false,
    },
  ];
  const [layers, setLayers] = useState(initialState);
  const customFilterRef = useRef({});

  const activeLayer = useCallback((id) => {
    const newLayers = layers.map(layer => (layer.id === id ? Object.assign(layer, { isActive: true }) : layer));
    setLayers(newLayers);
  }, [layers]);

  const disactiveLayer = useCallback((id) => {
    const newLayers = layers.map(layer => (layer.id === id ? Object.assign(layer, { isActive: false }) : layer));
    setLayers(newLayers);
  }, [layers]);

  const isLayerActive = layers.some(layer => layer.isActive);
  const isGalleryActive = layers.some(layer => layer.id === 'gallery' && layer.isActive);
  const isMenuActive = layers.some(layer => layer.id === 'menu' && layer.isActive);
  const isIntroActive = layers.some(layer => layer.id === 'intro' && layer.isActive);
  const isAboutActive = layers.some(layer => layer.id === 'about' && layer.isActive);

  return (
    <Provider value={{
      isLayerActive,
      isGalleryActive,
      isMenuActive,
      isIntroActive,
      isAboutActive,
      activeLayer,
      disactiveLayer,
      customFilterRef,
    }}
    >
      { children }
    </Provider>
  );
};

export {
  Context,
  Consumer,
};

export default LayerContext;
