import React from 'react';

const Context = React.createContext();

const {
  Consumer,
  Provider,
} = Context;

export {
  Context as default,
  Consumer,
  Provider,
};
