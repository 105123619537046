import * as PIXI from 'pixi.js-legacy';

export const loadPixiTexture = ({
  src,
  load = () => {},
  progress = () => {},
  complete = () => {},
}) => {
  const loader = new PIXI.Loader();
  let resources = [];
  if (typeof (src) === 'string') {
    resources.push(src);
  } else {
    resources.push(...src);
  }
  resources = resources.filter(Boolean).filter(i => !loader.resources[i]);
  loader.onLoad.add(e => load(e));
  loader.onProgress.add(e => progress(e));
  loader.onComplete.add(e => complete(e));
  if (resources.length === 0) return Promise.resolve();
  return new Promise(resolve => loader
    .add(resources.map(url => ({ url })))
    .load(resolve));
};

export const prepareFilter = ({
  vertex = null,
  fragment = null,
  uniforms = {},
  resolution = global.devicePixelRatio,
  autoFit = true,
  padding = 0,
  blendMode = 0,
}) => {
  const filter = new PIXI.Filter(vertex, fragment);
  filter.resolution = resolution;
  filter.padding = padding;
  filter.autoFit = autoFit;
  filter.autofit = autoFit;
  filter.blendMode = blendMode;
  Object.assign(filter.uniforms, { time: 0 }, uniforms);
  // eslint-disable-next-line
  filter.apply = function (filterManager, input, output, clear) {
    this.uniforms.dimensions = [];
    this.uniforms.time += 0.05;
    this.uniforms.dimensions[0] = input.filterFrame.width;
    this.uniforms.dimensions[1] = input.filterFrame.height;
    filterManager.applyFilter(this, input, output, clear);
  };
  return filter;
};

export const getObjectFitData = (pw, ph, w, h) => {
  if (pw / ph > w / h) {
    return {
      width: pw,
      height: (pw * h) / w,
      x: 0,
      y: (ph - (pw * h) / w) / 2,
    };
  }

  return {
    width: (ph * w) / h,
    height: ph,
    x: (pw - (ph * w) / h) / 2,
    y: 0,
  };
};

export const modulate = (value, rangeA, rangeB, limit) => {
  if (limit == null) {
    limit = false; // eslint-disable-line
  }
  const [fromLow, fromHigh] = Array.from(rangeA);
  const [toLow, toHigh] = Array.from(rangeB);
  const result = toLow + ((value - fromLow) / (fromHigh - fromLow)) * (toHigh - toLow);

  if (limit === true) {
    if (toLow < toHigh) {
      if (result < toLow) {
        return toLow;
      }
      if (result > toHigh) {
        return toHigh;
      }
    } else {
      if (result > toLow) {
        return toLow;
      }
      if (result < toHigh) {
        return toHigh;
      }
    }
  }

  return result;
};

export const lerp = (a, b, n) => (1 - n) * a + n * b;

export const norm = (value, min, max) => (value - min) / (max - min);

export const range = (value, min, max) => Math.min(Math.max(value, min), max);

export const prevIndex = (index, length, limit = false) => (index > 0 ? index - 1 : (limit ? 0 : length - 1)); // eslint-disable-line

export const nextIndex = (index, length, limit = false) => (index < length - 1 ? index + 1 : (limit ? length - 1 : 0)); // eslint-disable-line

export const directionByIndex = (prev, current, length) => {
  if (typeof (prev) !== 'number' || prev === -1) return null;
  return (
    (prev === 0 && current === length - 1)
    || (prev > current && prev - current < length - 1)
      ? 'prev'
      : 'next'
  );
};

export const calculatePan = (mouseY, containerHeight, from) => {
  const limit = containerHeight - global.innerHeight;
  return modulate(mouseY, [0, global.innerHeight], [from, -limit], true);
};

export const srcSetParser = (string) => {
  const array = string.replace(/\n/g, '').split(',');
  const keys = ['sm', 'md', 'lg'];
  return array.reduce((res, field, index) => {
    res[keys[index]] = field.replace(/\s.*$/g, '');
    return res;
  }, {});
};

export const omit = (object, keys) => {
  const omittedKeys = Array.isArray(keys) ? keys : [keys];

  return Object.entries(object)
    .filter(([key]) => !omittedKeys.includes(key))
    .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {});
};

export const chunk = (array, size) => {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i++) {
    const last = chunkedArr[chunkedArr.length - 1];
    if (!last || last.length === size) {
      chunkedArr.push([array[i]]);
    } else {
      last.push(array[i]);
    }
  }
  return chunkedArr;
};
