import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import anime from 'animejs';
import Slider from '@/components/Slider';
import Delayed from 'react-delayed';
import { Context } from '@/context';
import { Context as LayerContext } from '@/context/layer';
import { prevIndex, nextIndex, lerp, calculatePan } from '@/utils';
import { useRaf } from '@/package/ReactHandlers';
import useClickOutsideImg from '@/hooks/useClickOutsideImg';
import useDebouncedBoolean from '@/hooks/useDebouncedBoolean';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import styles from './Gallery.module.css';

const Gallery = () => {
  const [index, setIndex] = useState(0);
  const { comic, currentGalleryThumb, currentSection, setCloseGallery } = useContext(Context);
  const { isGalleryActive, disactiveLayer } = useContext(LayerContext);
  const imgRef = useRef();
  const contentRef = useRef();
  const wrapperRef = useRef();
  const isActive = useDebouncedBoolean([index], 1000);
  const imgHeight = useRef(0);
  const pan = useRef(0);
  const galleryCollection = useRef([]);
  const enablePan = useRef(false);

  const prev = useCallback(() => {
    if (isActive) return;
    setIndex(i => prevIndex(i, galleryCollection.current.length));
  }, [isActive]);

  const next = useCallback(() => {
    if (isActive) return;
    setIndex(i => nextIndex(i, galleryCollection.current.length));
  }, [isActive]);

  const handleClickOutside = (e) => {
    if (e.pageX < global.innerWidth / 2) prev();
    if (e.pageX > global.innerWidth / 2) next();
  };

  useClickOutsideImg(imgRef, e => handleClickOutside(e));

  const closeGallery = () => disactiveLayer('gallery');

  const onMouseMove = (e) => {
    pan.current = calculatePan(e.pageY, imgHeight.current, 0);
  };

  useRaf(() => {
    if (enablePan.current) {
      const oldY = parseInt(anime.get(contentRef.current, 'translateY'));
      const lerpY = pan.current > 0 ? 0 : lerp(oldY, pan.current, 0.1);
      anime.set(contentRef.current, { translateY: lerpY });
    }
  }, [pan, isGalleryActive]);

  useEffect(() => {
    global.addEventListener('mousemove', onMouseMove);
    return () => {
      global.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  const prepareEnter = () => anime.set(wrapperRef.current, { translateY: '130%' });

  useEffect(() => {
    if (contentRef.current) imgHeight.current = parseInt(anime.get(contentRef.current, 'height'));
    prepareEnter();
  }, [contentRef.current]);

  useUpdateEffect(() => {
    if (isGalleryActive) {
      setTimeout(() => {
        anime({
          targets: wrapperRef.current,
          easing: 'easeInOutQuad',
          translateY: 0,
          complete: () => enablePan.current = true,
        });
      }, 0);
    } else {
      anime({
        begin: () => enablePan.current = false,
        targets: wrapperRef.current,
        easing: 'easeInOutQuad',
        translateY: '-130%',
      });
    }
  }, [isGalleryActive]);

  useEffect(() => {
    galleryCollection.current = comic[currentSection === -1 ? 0 : currentSection].node.gallery;
  }, [currentSection]);

  useUpdateEffect(() => {
    const currentIndex = galleryCollection.current.findIndex(img => img.url === currentGalleryThumb);
    setIndex(currentIndex);
  }, [currentGalleryThumb]);

  const hanldeMouse = useCallback((e) => {
    setCloseGallery(e.type === 'mouseenter');
  });

  return (
    <Delayed
      mounted={isGalleryActive}
      unmountAfter={1000}
    >
      <div
        ref={wrapperRef}
        className={styles.wrapper}
      >
        <div
          ref={contentRef}
          className={styles.content}
        >
          <Slider
            className={styles.imageSlider}
            mask
            hCenter
            index={index}
            autofit
            activeClassName={styles.active}
          >
            {galleryCollection.current.map(img => (
              <div
                key={img.id}
                className={styles.item}
              >
                <img
                  ref={imgRef}
                  src={img.url}
                  alt={img.id}
                  className={styles.image}
                  onClick={closeGallery}
                  onMouseEnter={hanldeMouse}
                  onMouseLeave={hanldeMouse}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </Delayed>
  );
};

export default Gallery;
