import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as PIXI from 'pixi.js-legacy';
import { isMobile } from 'react-device-detect';
import { Context } from '@/context';
import { useResize } from '@/package/ReactHandlers';
import { GUTTER } from '@/constants';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import useBreakpoints from '@/hooks/useBreakpoints';
import PixiSprite from '@/components/PixiSprite';

const PixiGrid = ({
  items,
  index,
  container,
  layout,
}) => {
  const { isPixiLoaderComplete } = useContext(Context);
  const gridRef = useRef();
  const { screen } = useBreakpoints();

  const createGrid = () => {
    gridRef.current = new PIXI.Container();
    gridRef.current.name = `PIXIGRID_${index}`;
  };

  useEffect(() => {
    createGrid();
  }, []);

  useUpdateEffect(() => {
    container.current.addChild(gridRef.current);
  }, [container.current]);

  const setGridPositions = () => {
    const prevGrids = container.current.children.slice(0, index);
    const prevGridsHeight = prevGrids.reduce((a, b) => a + b.height, 0);
    gridRef.current.y = prevGridsHeight + ((isMobile ? GUTTER[screen] : GUTTER[screen] * 3) * (index + 1));
    if (isMobile && index === 0) gridRef.current.y = GUTTER[screen];
  };

  useUpdateEffect(() => {
    setGridPositions();
  }, [isPixiLoaderComplete]);

  useResize(() => {
    if (container.current) setGridPositions();
  }, [container.current]);

  return items.map((image, i) => (
    <PixiSprite
      key={image.id}
      texture={image}
      container={gridRef}
      isReady={isPixiLoaderComplete}
      index={i}
      layout={layout}
    />
  ));
};

PixiGrid.propTypes = { items: PropTypes.array.isRequired };

export default PixiGrid;
