/* global localStorage */
import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import anime from 'animejs';
import PropTypes from 'prop-types';
import { Context } from '@/context';
import { Context as LayerContext } from '@/context/layer';
import { Scrollbar } from '@/context/scrollbar';
import Cta from '@/components/Cta';
import { useRaf } from '@/package/ReactHandlers';
import Sun from '@/assets/svg-inline/sun.svg';
import Close from '@/assets/svg-inline/close.svg';
import styles from './Interface.module.css';

const Interface = ({ social }) => {
  const { siteHeight, language, isPixiLoaderComplete, isDefaultTimeLoaderComplete } = useContext(Context);
  const { scroll, scrollTo } = useContext(Scrollbar);
  const { activeLayer, disactiveLayer, isIntroActive, isAboutActive, isGalleryActive, isMenuActive, isLayerActive } = useContext(LayerContext);
  const [isBottom, setIsBottom] = useState(false);
  const logoRef = useRef();

  const handleClickLogo = useCallback(() => activeLayer('about'));
  const handleClickClose = useCallback(() => disactiveLayer('about'));
  const handleClickBackTop = useCallback(() => scrollTo(0));

  useRaf(() => {
    if (Math.abs(scroll.current) > ((siteHeight - global.innerHeight) - 50) && !isBottom) {
      setIsBottom(true);
    }
    if (Math.abs(scroll.current) < ((siteHeight - global.innerHeight) - 50) && isBottom) {
      setIsBottom(false);
    }
  }, [siteHeight, isBottom]);

  useEffect(() => {
    anime({
      begin: !isIntroActive ? () => logoRef.current.classList.add(styles.hover) : () => {},
      targets: logoRef.current,
      easing: 'easeOutCubic',
      translateY: isIntroActive ? '50px' : 0,
      opacity: 1,
      complete: !isIntroActive ? () => logoRef.current.classList.remove(styles.hover) : () => {},
    });
  }, [isIntroActive]);

  const handleClickLang = (lang) => {
    localStorage.setItem('language', lang);
    global.location.reload();
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div
          ref={logoRef}
          className={styles.logoWrapper}
        >
          <Sun
            data-button
            className={classNames({
              [styles.logo]: true,
              [styles.uiButton]: true,
              [styles.visible]: !isGalleryActive && !isAboutActive && !isMenuActive,
              [styles.rotating]: !isPixiLoaderComplete || !isDefaultTimeLoaderComplete,
            })}
            onClick={!isIntroActive ? handleClickLogo : undefined}
          />
        </div>
        <div
          data-button
          className={classNames({
            [styles.close]: true,
            [styles.uiButton]: true,
            [styles.visible]: isAboutActive,
          })}
          onClick={handleClickClose}
        >
          <Close />
        </div>
      </div>
      <div className={styles.footer}>
        <a
          className={classNames({
            [styles.left]: true,
            [styles.uiButton]: true,
            [styles.link]: true,
            [styles.visible]: isAboutActive || (isBottom && siteHeight !== 0 && !isMenuActive),
          })}
          dangerouslySetInnerHTML={{ __html: '&copy; EMPI 2019' }}
          href="https://www.instagram.com/empi.me/"
          target="__blank"
        />
        <ul
          className={classNames({
            [styles.center]: true,
            [styles.uiButton]: true,
            [styles.visible]: isAboutActive,
          })}
        >
          {social.map((item, i) => (
            <li
              key={i}
              className={styles.menuItem}
            >
              <Cta
                text={item.node.profileType}
                component="a"
                href={item.node.url}
                className={classNames({
                  [styles.cta]: true,
                  [styles.link]: true,
                })}
                target="__blank"
                underline
              />
            </li>
          ))}
        </ul>
        <Cta
          text="Back to top"
          component="button"
          className={classNames({
            [styles.center]: true,
            [styles.cta]: true,
            [styles.link]: true,
            [styles.uiButton]: true,
            [styles.visible]: isBottom && !isAboutActive && siteHeight !== 0 && !isMenuActive,
          })}
          underline
          onClick={handleClickBackTop}
        />
        <a
          className={classNames({
            [styles.right]: true,
            [styles.uiButton]: true,
            [styles.link]: true,
            [styles.visible]: isBottom && siteHeight !== 0 && !isLayerActive,
          })}
          dangerouslySetInnerHTML={{ __html: 'code by FRICONELLI' }}
          href="https://www.linkedin.com/in/friconelli/"
          target="__blank"
        />
        <span
          className={classNames({
            [styles.right]: true,
            [styles.menuLang]: true,
            [styles.uiButton]: true,
            [styles.link]: true,
            [styles.visible]: isAboutActive && siteHeight !== 0,
          })}
        >
          <span
            className={classNames({
              [styles.language]: true,
              [styles.active]: language === 'it',
            })}
            dangerouslySetInnerHTML={{ __html: 'IT' }}
            onClick={() => handleClickLang('it')}
          />
          <span
            className={classNames({
              [styles.language]: true,
              [styles.active]: language === 'en',
            })}
            dangerouslySetInnerHTML={{ __html: 'EN' }}
            onClick={() => handleClickLang('en')}
          />
        </span>
      </div>
    </div>
  );
};

Interface.propTypes = { social: PropTypes.array.isRequired };

export default Interface;
