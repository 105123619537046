/* global navigator */
import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useRaf } from '@/package/ReactHandlers';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import VirtualScroll from 'virtual-scroll';
import { Context } from '@/context';
import { Context as LayerContext } from '@/context/layer';

const Scrollbar = React.createContext({});
const {
  Provider,
  Consumer,
} = Scrollbar;

const ScrollbarComponent = ({
  ease,
  children,
}) => {
  const { siteHeight } = useContext(Context);
  const { isLayerActive } = useContext(LayerContext);
  const { innerHeight } = global;
  const vs = useRef();
  const target = useRef(0);
  const scroll = useRef(0);
  const delta = useRef(0);

  useRaf(() => {
    scroll.current += (target.current - scroll.current) * ease;
  }, []);

  useUpdateEffect(() => {
    vs.current = new VirtualScroll({
      mouseMultiplier: navigator.platform.indexOf('Win') > -1 ? 1 : 0.4,
      touchMultiplier: 4,
      firefoxMultiplier: 30,
      preventTouch: true,
    });
    vs.current.on((e) => {
      if (isLayerActive) return;
      delta.current = e.deltaY;
      target.current += e.deltaY;
      target.current = Math.max((siteHeight - innerHeight) * -1, target.current);
      target.current = Math.min(0, target.current);
    });

    return () => {
      if (vs.current) vs.current.destroy();
    };
  }, [siteHeight, isLayerActive]);

  const scrollTo = (value) => {
    target.current = -Math.abs(value);
  };

  return (
    <Provider value={{
      scroll,
      delta,
      scrollTo,
    }}
    >
      { children }
    </Provider>
  );
};

ScrollbarComponent.propTypes = { ease: PropTypes.number };

ScrollbarComponent.defaultProps = { ease: 0.05 };

export {
  Scrollbar,
  Consumer,
};

export default ScrollbarComponent;
