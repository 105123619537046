/* global dispatchEvent, requestAnimationFrame, cancelAnimationFrame */
import React, { useRef, useCallback, useEffect, useState, useContext } from 'react';
import Velocity from 'touch-velocity';
import CustomEvent from 'custom-event';
import { isMobile } from 'react-device-detect';
import { useRaf, useResize } from '@/package/ReactHandlers';
import { Context as LayerContext } from '@/context/layer';
import { range, modulate } from '@/utils';
import useWillMount from '@/hooks/useWillMount';
import useUpdateEffect from '@/hooks/useUpdateEffect';

const Context = React.createContext({});

const Mouse = ({ children }) => {
  const contextRef = useRef();
  const velocityRef = useRef();
  const timerId = useRef();
  const counter = useRef(0);
  const pressHoldEvent = useRef();
  const pressHoldDuration = useRef(30);
  const [isPressHold, setIsPressHold] = useState(false);
  const isSpriteOver = useRef(false);
  const { isLayerActive, activeLayer, disactiveLayer, isGalleryActive } = useContext(LayerContext);
  const [galleryDirection, setGalleryDirection] = useState('prev');

  const prevMouseRef = useRef({
    x: 0,
    y: 0,
  });

  const mouseRef = useRef({
    x: global.innerWidth / 2,
    y: global.innerHeight / 2,
    normalizedX: 0,
    normalizedY: 0,
  });

  const screenSizeRef = useRef({
    width: 0,
    height: 0,
  });

  const scrollbarRef = useRef({
    y: 0,
    yVelocity: 0,
    yDirection: 0,
    yViewportPercent: 0,
    prevY: 0,
    prevYViewportPercent: 0,
  });

  const onMouseMove = useCallback((e) => {
    prevMouseRef.current.x = mouseRef.current.x;
    prevMouseRef.current.y = mouseRef.current.y;
    mouseRef.current.x = e.pageX;
    mouseRef.current.y = e.pageY;
    mouseRef.current.normalizedX = modulate(
      mouseRef.current.x,
      [0, screenSizeRef.current.width],
      [-1, 1],
    );
    mouseRef.current.normalizedY = modulate(
      mouseRef.current.y,
      [0, screenSizeRef.current.height],
      [-1, 1],
    );
    if (isGalleryActive) {
      if (
        e.pageX < global.innerWidth / 2
        && galleryDirection !== 'prev'
      ) setGalleryDirection('prev');
      if (
        e.pageX > global.innerWidth / 2
        && galleryDirection !== 'next'
      ) setGalleryDirection('next');
    }
  }, [isGalleryActive, galleryDirection]);

  useEffect(() => {
    pressHoldEvent.current = new CustomEvent('pressHold');
  }, []);

  useWillMount(() => {
    velocityRef.current = new Velocity();
    contextRef.current = {
      prevMouse: prevMouseRef,
      mouse: mouseRef,
      scrollbar: scrollbarRef,
      screenSize: screenSizeRef,
    };
  });

  useResize(() => {
    screenSizeRef.current = {
      width: global.innerWidth,
      height: global.innerHeight,
    };
  });

  useRaf(() => {
    scrollbarRef.current.prevYViewportPercent = scrollbarRef.current.yViewportPercent;
    scrollbarRef.current.yViewportPercent = modulate(
      scrollbarRef.current.y,
      [0, screenSizeRef.current.height * 1],
      [0, 1],
      true,
    );
    velocityRef.current.updatePosition(scrollbarRef.current.y);
    scrollbarRef.current.yVelocity = range(
      velocityRef.current.getVelocity() * 0.001,
      -1,
      1,
    );
    scrollbarRef.current.yVelocity = velocityRef.current.getVelocity() * 0.001;
  });

  const timer = () => {
    if (counter.current < pressHoldDuration.current) {
      timerId.current = requestAnimationFrame(timer);
      counter.current += 1;
    } else {
      dispatchEvent(pressHoldEvent.current);
    }
  };

  const onPressingDown = useCallback((e) => {
    if (
      e.which === 3
      || (e.target.dataset && e.target.dataset.button === 'true')
      || isSpriteOver.current
      || isLayerActive
    ) return;
    requestAnimationFrame(timer);
    e.preventDefault();
  }, [isLayerActive]);

  const onPressingLeave = useCallback(() => {
    cancelAnimationFrame(timerId.current);
    counter.current = 0;
    if (isPressHold) setIsPressHold(false);
  }, [isPressHold]);

  const onPressAndHold = useCallback(() => {
    if (!isMobile) setIsPressHold(true);
  });

  useUpdateEffect(() => {
    if (isPressHold) {
      activeLayer('menu');
    } else {
      disactiveLayer('menu');
    }
  }, [isPressHold]);

  useEffect(() => {
    global.addEventListener('mousedown', onPressingDown);
    global.addEventListener('touchstart', onPressingDown);
    return () => {
      global.removeEventListener('mousedown', onPressingDown);
      global.removeEventListener('touchstart', onPressingDown);
    };
  }, [isLayerActive]);

  useEffect(() => {
    global.addEventListener('mousemove', onMouseMove);
    return () => {
      global.removeEventListener('mousemove', onMouseMove);
    };
  }, [isGalleryActive, galleryDirection]);

  useEffect(() => {
    global.addEventListener('pressHold', onPressAndHold);
    return () => {
      global.removeEventListener('pressHold', onPressAndHold);
    };
  }, []);

  useEffect(() => {
    global.addEventListener('mouseup', onPressingLeave);
    global.addEventListener('touchend', onPressingLeave);
    return () => {
      global.removeEventListener('mouseup', onPressingLeave);
      global.removeEventListener('touchend', onPressingLeave);
    };
  }, [isPressHold]);

  return (
    <Context.Provider
      value={{
        ...contextRef.current,
        isSpriteOver,
        counterPress: counter,
        galleryDirection,
        isPressHold,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context };

export default Mouse;
