import { useState, useEffect } from 'react';

export default function useBreakpoints(arg = {
  sm:	{
    min: 0,
    max: 767,
  },
  md: {
    min: 767,
    max: 991,
  },
  lg: {
    min: 991,
    max: null,
  },
}) {
  const [points, setBreakpoints] = useState({});
  const breakpoints = {};
  const handleResize = () => {
    for (const prop in arg) { // eslint-disable-line
      if (arg[prop].hasOwnProperty('min') && arg[prop].hasOwnProperty('max')) { // eslint-disable-line no-prototype-builtins
        if (arg[prop].max) {
          breakpoints[prop] = ((arg[prop].max > global.innerWidth) && (arg[prop].min < global.innerWidth));
        } else {
          breakpoints[prop] = (arg[prop].min < global.innerWidth);
        }
      } else {
        throw new Error(`${prop} must have properties min and max`);
      }
    }
    breakpoints.screen = Object.keys(breakpoints).filter(prop => (breakpoints[prop] && prop !== 'screen') && prop).toString();
    setBreakpoints(breakpoints);
  };

  useEffect(() => handleResize(), []);

  useEffect(() => {
    global.addEventListener('resize', handleResize);
    return () => {
      global.removeEventListener('resize', handleResize);
    };
  });

  return points;
}
