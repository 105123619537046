/* global document, localStorage */
import React, { useState, useRef, useEffect } from 'react';
import useWillMount from '@/hooks/useWillMount';
import { StaticQuery, graphql } from 'gatsby';

const Context = React.createContext({});
const {
  Provider,
  Consumer,
} = Context;

const ContextComponent = ({ children }) => {
  const [isPixiLoaderComplete, setIsPixiLoaderComplete] = useState(false);
  const [currentGalleryThumb, setCurrentGalleryThumb] = useState('');
  const [siteHeight, setSiteHeight] = useState(0);
  const [currentSection, setCurrentSection] = useState(-1);
  const [sectionFromMenu, setSectionFromMenu] = useState(undefined);
  const pixiResources = useRef({});
  const [isIdle, setIsIdle] = useState(true);
  const [closeGallery, setCloseGallery] = useState(true);
  const [isIntroCompleted, setIsIntroCompleted] = useState(false);
  const [language, setLanguage] = useState('');
  const [isDefaultTimeLoaderComplete, setDefaultTimeLoaderComplete] = useState(false);
  let timeoutID = 0;

  useWillMount(() => {
    if (typeof window !== 'undefined') {
      setLanguage(localStorage.getItem('language') || 'it');
    } else {
      setLanguage('it');
    }
  });

  const startTimer = () => {
    timeoutID = setTimeout(() => setIsIdle(true), 8000);
  };

  const goActive = () => {
    setIsIdle(false);
    startTimer();
  };

  const resetTimer = () => {
    clearTimeout(timeoutID);
    goActive();
  };

  useEffect(() => {
    document.body.addEventListener('mousemove', resetTimer);
    document.body.addEventListener('mousedown', resetTimer);
    document.body.addEventListener('keypress', resetTimer);
    document.body.addEventListener('DOMMouseScroll', resetTimer);
    document.body.addEventListener('mousewheel', resetTimer);
    document.body.addEventListener('touchmove', resetTimer);
    document.body.addEventListener('MSPointerMove', resetTimer);
    startTimer();
    return () => {
      document.body.removeEventListener('mousemove', resetTimer);
      document.body.removeEventListener('mousedown', resetTimer);
      document.body.removeEventListener('keypress', resetTimer);
      document.body.removeEventListener('DOMMouseScroll', resetTimer);
      document.body.removeEventListener('mousewheel', resetTimer);
      document.body.removeEventListener('touchmove', resetTimer);
      document.body.removeEventListener('MSPointerMove', resetTimer);
    };
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query ContextQuery {
          comic: allDatoCmsComic(sort: {fields: position}) {
            edges {
              node {
                title
                titleEn
                gallery {
                  url
                  id
                }
                galleryEn {
                  url
                  id
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Provider value={{
          isPixiLoaderComplete,
          setIsPixiLoaderComplete,
          siteHeight,
          setSiteHeight,
          currentSection,
          setCurrentSection,
          comic: data.comic.edges,
          pixiResources,
          currentGalleryThumb,
          setCurrentGalleryThumb,
          sectionFromMenu,
          setSectionFromMenu,
          isIdle,
          setCloseGallery,
          closeGallery,
          isIntroCompleted,
          setIsIntroCompleted,
          language,
          isDefaultTimeLoaderComplete,
          setDefaultTimeLoaderComplete,
        }}
        >
          { children }
        </Provider>
      )}
    />
  );
};

export {
  Context,
  Consumer,
};

export default ContextComponent;
