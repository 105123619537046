import React, { useContext, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as PIXI from 'pixi.js-legacy';
import { isMobile } from 'react-device-detect';
import { PixiContext } from '@/context/pixi';
import { Context } from '@/context';
import { useResize } from '@/package/ReactHandlers';
import { Y_MARGIN } from '@/constants';
import { chunk } from '@/utils';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import useScrollbar from '@/hooks/useScrollbar';
import useBreakpoints from '@/hooks/useBreakpoints';
import PixiGrid from '@/components/PixiGrid';

const PixiMedia = ({
  chapter,
  index,
}) => {
  const { mainContainer } = useContext(PixiContext);
  const { isPixiLoaderComplete, setCurrentSection, currentSection, language } = useContext(Context);
  const containerRef = useRef();
  const bounds = useRef({ minY: 0, maxY: 0 });
  const isPositioned = useRef(false);
  const { screen } = useBreakpoints();

  const createGrid = () => {
    containerRef.current = new PIXI.Container();
    containerRef.current.name = `GRID_${index}`;
  };

  const updateBounds = useCallback(() => {
    Object.assign(bounds.current, {
      minY: containerRef.current.y - Y_MARGIN[screen], // eslint-disable-line no-underscore-dangle
      maxY: containerRef.current.y + containerRef.current.height, // eslint-disable-line no-underscore-dangle
    });
  }, [screen]);

  useEffect(() => {
    createGrid(chapter);
  }, []);

  useUpdateEffect(() => {
    mainContainer.addChild(containerRef.current);
  }, [mainContainer]);

  const setGridPositions = () => {
    const prevGrids = mainContainer.children.slice(0, index);
    const prevGridsHeight = prevGrids.reduce((a, b) => a + b.height, 0);
    containerRef.current.y = prevGridsHeight + (Y_MARGIN[screen] * (index + 1));
    if (isMobile && index === 0) containerRef.current.y = Y_MARGIN.lg;
    isPositioned.current = !isPositioned.current;
  };

  useUpdateEffect(() => {
    updateBounds();
  }, [isPositioned.current]);

  useUpdateEffect(() => {
    setGridPositions();
  }, [isPixiLoaderComplete]);

  useResize(() => {
    if (mainContainer) setGridPositions();
  }, [mainContainer]);

  useScrollbar(({ y }) => {
    if (
      y > bounds.current.minY
      && y < bounds.current.maxY
      && index !== currentSection
      && y !== 0
    ) {
      setCurrentSection(index);
    }
  }, [bounds.current, currentSection]);

  return chunk(chapter.node[language === 'it' ? 'gallery' : 'galleryEn'], 4).map((items, i) => (
    <PixiGrid
      key={i}
      items={items}
      container={containerRef}
      index={i}
      layout={chapter.node.layoutType || 1}
    />
  ));
};

PixiMedia.propTypes = {
  chapter: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default PixiMedia;
