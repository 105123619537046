import React, { useRef, useContext, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import anime from 'animejs';
import { Context } from '@/context';
import { Context as LayerContext } from '@/context/layer';
import useCollectionRef from '@/hooks/useCollectionRef';
import styles from './Intro.module.css';

const Intro = ({ data }) => {
  const { isIntroActive, disactiveLayer } = useContext(LayerContext);
  const { setIsIntroCompleted, language, isPixiLoaderComplete, setDefaultTimeLoaderComplete, isDefaultTimeLoaderComplete } = useContext(Context);
  const { title, suggestLine1, suggestLine2, titleEn, suggestLine1En, suggestLine2En } = data;
  const [onGetNode, nodesRef] = useCollectionRef();
  const rootRef = useRef();
  const dataRef = useRef({
    it: {
      title,
      suggestLine1,
      suggestLine2,
    },
    en: {
      title: titleEn,
      suggestLine1: suggestLine1En,
      suggestLine2: suggestLine2En,
    },
  });

  const prepareEnter = useCallback(() => {
    anime.set(nodesRef.current, {
      translateY: '-100px',
      opacity: 0,
    });
  });

  useEffect(() => {
    if (isIntroActive) prepareEnter();
    anime({
      targets: nodesRef.current,
      delay: (el, index) => 200 + (index * 25),
      easing: 'easeOutCubic',
      translateY: 0,
      opacity: 1,
    });
  }, [nodesRef.current]);

  useEffect(() => {
    if (!isIntroActive) {
      anime({
        targets: rootRef.current,
        easing: 'easeInOutQuad',
        opacity: 0,
        complete: () => setIsIntroCompleted(true),
      });
    }
  }, [isIntroActive]);

  useEffect(() => {
    setTimeout(() => {
      setDefaultTimeLoaderComplete(true);
    }, 6000);
  }, []);

  useEffect(() => {
    if (isPixiLoaderComplete && isDefaultTimeLoaderComplete) {
      setTimeout(() => {
        anime({
          begin: () => disactiveLayer('intro'),
          targets: nodesRef.current,
          delay: (el, index) => 200 + (index * 25),
          easing: 'easeOutCubic',
          translateY: '-100px',
          opacity: 0,
        });
      }, 50);
    }
  }, [isPixiLoaderComplete, isDefaultTimeLoaderComplete]);

  return (
    <div
      ref={rootRef}
      className={classNames({
        [styles.root]: true,
        [styles.open]: isIntroActive,
      })}
    >
      <h2
        ref={onGetNode(0)}
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: dataRef.current[language] && dataRef.current[language].title }}
      />
      {!isMobile && (
        <div className={styles.description}>
          {[
            dataRef.current[language] && dataRef.current[language].suggestLine1,
            dataRef.current[language] && dataRef.current[language].suggestLine2,
          ].map((item, i) => (
            <span
              key={i}
              className={styles.line}
              ref={onGetNode(i + 1)}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

Intro.propTypes = { data: PropTypes.object.isRequired };

export default Intro;
