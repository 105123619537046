import React, { useRef, useContext, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import anime from 'animejs';
import Delayed from 'react-delayed';
import { Context } from '@/context';
import { Context as LayerContext } from '@/context/layer';
import Cta from '@/components/Cta';
import useCollectionRef from '@/hooks/useCollectionRef';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import styles from './About.module.css';

const About = ({ data }) => {
  const { isAboutActive } = useContext(LayerContext);
  const { language } = useContext(Context);
  const { title, description, descriptionEn, ctaText, ctaTextEn, ctaLink } = data;
  const [onGetNode, nodesRef] = useCollectionRef();
  const descriptionRef = useRef();
  const dataRef = useRef({
    it: {
      title,
      description,
      ctaText,
      ctaLink,
    },
    en: {
      title,
      description: descriptionEn,
      ctaText: ctaTextEn,
      ctaLink,
    },
  });

  const prepareEnter = useCallback(() => {
    anime.set(nodesRef.current, {
      translateY: '-100px',
      opacity: 0,
    });
  });

  useUpdateEffect(() => {
    if (isAboutActive) prepareEnter();
    setTimeout(() => {
      anime({
        targets: nodesRef.current,
        duration: 1000,
        delay: (el, index) => 200 + (index * 25),
        easing: 'easeOutCubic',
        translateY: isAboutActive ? 0 : '-100px',
        opacity: isAboutActive ? 1 : 0,
      });
    }, isAboutActive ? 800 : 0);
  }, [isAboutActive]);

  const handleMouseMove = (e) => {
    if (nodesRef.current.length !== 0) {
      const rect = descriptionRef.current.getBoundingClientRect();
      const light = nodesRef.current[20].getBoundingClientRect();
      const x = (e.clientX - rect.left) - (light.width / 2);
      const y = (e.clientY - rect.top) - (light.height / 2);
      Object.assign(nodesRef.current[20].style, {
        top: `${y}px`,
        left: `${x}px`,
      });
    }
  };

  return (
    <Delayed
      mounted={isAboutActive}
      unmountAfter={1000}
    >
      <div
        className={classNames({
          [styles.root]: true,
          [styles.open]: isAboutActive,
        })}
      >
        <h4
          ref={onGetNode(0)}
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: dataRef.current[language] && dataRef.current[language].title }}
        />
        <div
          ref={descriptionRef}
          className={styles.description}
          onMouseMove={handleMouseMove}
        >
          {dataRef.current[language] && dataRef.current[language].description.split('\n').map((item, i) => (
            <span
              key={i}
              className={styles.line}
              ref={onGetNode(i + 1)}
              dangerouslySetInnerHTML={{ __html: item }}
            />
          ))}
          <div
            ref={onGetNode(20)}
            className={styles.light}
          />
        </div>
        <Cta
          ref={onGetNode(21)}
          text={dataRef.current[language] ? dataRef.current[language].ctaText : ''}
          component="a"
          href={ctaLink}
          className={styles.cta}
          target="__blank"
          underline
        />
      </div>
    </Delayed>
  );
};

About.propTypes = { data: PropTypes.object.isRequired };

export default About;
