import { useEffect } from 'react';

export default (ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || event.target.nodeName === 'IMG') {
          return;
        }

        handler(event);
      };

      global.addEventListener('mousedown', listener);
      global.addEventListener('touchstart', listener);

      return () => {
        global.removeEventListener('mousedown', listener);
        global.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler],
  );
};
