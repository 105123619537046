import React, { useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { isBrowser } from 'react-device-detect';
import Handlers from '@/package/ReactHandlers';
import Pixi from '@/components/Pixi';
import PixiMedia from '@/components/PixiMedia';
import PixiFilters from '@/components/PixiFilters';
import ContextGeneral from '@/context';
import LayerContext from '@/context/layer';
import MouseContext from '@/context/mouse';
import ScrollbarComponent from '@/context/scrollbar';
import ShaderOverlay from '@/components/ShaderOverlay';
import PlainOverlay from '@/components/PlainOverlay';
import Menu from '@/components/Menu';
import About from '@/components/About';
import Interface from '@/components/Interface';
import Cursor from '@/components/Cursor';
import Intro from '@/components/Intro';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import '@/styles/index.css';

const TemplateWrapper = ({ children }) => {
  useEffect(() => {
    ReactGA.initialize('UA-154547784-1');
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          datoCmsSite {
            globalSeo {
              siteName
              fallbackSeo {
                description
                title
              }
            }
            faviconMetaTags {
              ...GatsbyDatoCmsFaviconMetaTags
            }
          }
          social: allDatoCmsSocialProfile(sort: { fields: [position], order: ASC }) {
            edges {
              node {
                profileType
                url
              }
            }
          }
          about: allDatoCmsAboutOverlay {
            edges {
              node {
                ctaLink
                ctaText
                description
                title
                descriptionEn
                ctaTextEn
              }
            }
          }
          intro: allDatoCmsIntroOverlay {
            edges {
              node {
                suggestLine1
                suggestLine2
                title
                suggestLine1En
                suggestLine2En
                titleEn
              }
            }
          }
          comic: allDatoCmsComic(sort: {fields: position}) {
            edges {
              node {
                chapterNumber
                title
                titleEn
                gallery {
                  url
                  id
                  fluid {
                    srcSet
                  }
                }
                galleryEn {
                  url
                  id
                  fluid {
                    srcSet
                  }
                }
                layoutType
              }
            }
          }
        }
      `}
      render={data => (
        <Handlers>
          <ContextGeneral>
            <LayerContext>
              <MouseContext>
                <ScrollbarComponent>
                  <div className="container">
                    <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />
                    <Helmet>
                      <title>{data.datoCmsSite.globalSeo.fallbackSeo.title}</title>
                      <meta name="description" content={data.datoCmsSite.globalSeo.fallbackSeo.description} />
                    </Helmet>
                    <Menu items={data.comic.edges.map(item => ({ title: item.node.title, chapterNumber: item.node.chapterNumber }))} />
                    <div className="container__canvas">
                      <Pixi data={data.comic.edges}>
                        {data.comic.edges.map((item, i) => (
                          <PixiMedia
                            key={i} // eslint-disable-line react/no-array-index-key
                            chapter={item}
                            index={i}
                          />
                        ))}
                        <PixiFilters />
                        <ShaderOverlay />
                        <PlainOverlay />
                        <About data={data.about.edges[0].node} />
                      </Pixi>
                    </div>
                    <div className="container__body">{children}</div>
                    {isBrowser && <Cursor />}
                  </div>
                  <section className="container__intro"><Intro data={data.intro.edges[0].node} /></section>
                  <section className="container__interface"><Interface social={data.social.edges} /></section>
                </ScrollbarComponent>
              </MouseContext>
            </LayerContext>
          </ContextGeneral>
        </Handlers>
      )}
    />
  );
};

export default TemplateWrapper;
