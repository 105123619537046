export const SIZES = {
  small: 1,
  medium: 1.5,
  large: 1.8,
  xlarge: 2.2,
};

export const IMAGE_WIDTH = {
  sm: {
    width: 300,
    height: 425,
  },
  md: {
    width: 190,
    height: 269,
  },
  lg: {
    width: 300,
    height: 425,
  },
};

export const SIZE_ORDER = {
  1: ['small', 'medium', 'large', 'xlarge', 'small', 'medium', 'large', 'xlarge', 'small', 'medium', 'large', 'xlarge', 'small', 'medium', 'large', 'xlarge', 'small', 'medium', 'large', 'xlarge'],
  2: ['xlarge', 'large', 'medium', 'small', 'xlarge', 'large', 'medium', 'small', 'xlarge', 'large', 'medium', 'small', 'xlarge', 'large', 'medium', 'small', 'xlarge', 'large', 'medium', 'small'],
  3: ['small', 'large', 'medium', 'medium', 'small', 'large', 'medium', 'medium', 'small', 'large', 'medium', 'medium', 'small', 'large', 'medium', 'medium', 'small', 'large', 'medium', 'medium'],
  4: ['medium', 'large', 'xlarge', 'small', 'medium', 'large', 'xlarge', 'small', 'medium', 'large', 'xlarge', 'small', 'medium', 'large', 'xlarge', 'small', 'medium', 'large', 'xlarge', 'small'],
};

export const GUTTER = {
  sm: 50,
  md: 30,
  lg: 50,
};

export const Y_MARGIN = {
  sm: global.innerHeight,
  md: global.innerHeight / 1.5,
  lg: global.innerHeight / 1.5,
};

export const WARP_FACTOR = {
  sm: 20,
  md: 200,
  lg: 200,
};
