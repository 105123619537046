import {
  REMOVE_WINDOW_CLICK_HANDLER,
  REMOVE_RAF_HANDLER,
  REMOVE_RESIZE_HANDLER,
  REMOVE_SCROLL_HANDLER,
  SAVE_WINDOW_CLICK_HANDLER,
  SAVE_RAF_HANDLER,
  SAVE_RESIZE_HANDLER,
  SAVE_SCROLL_HANDLER,
} from './constants';

const saveRafHandler = (fn, position) => ({
  type: SAVE_RAF_HANDLER,
  payload: { fn, position },
});
const removeRafHandler = (fn, position) => ({
  type: REMOVE_RAF_HANDLER,
  payload: { fn, position },
});
const saveResizeHandler = (fn, position) => ({
  type: SAVE_RESIZE_HANDLER,
  payload: { fn, position },
});
const removeResizeHandler = fn => ({
  type: REMOVE_RESIZE_HANDLER,
  payload: { fn },
});
const saveWindowClickHandler = (fn, position) => ({
  type: SAVE_WINDOW_CLICK_HANDLER,
  payload: { fn, position },
});
const removeWindowClickHandler = fn => ({
  type: REMOVE_WINDOW_CLICK_HANDLER,
  payload: { fn },
});
const saveScrollHandler = (fn, position) => ({
  type: SAVE_SCROLL_HANDLER,
  payload: { fn, position },
});
const removeScrollHandler = fn => ({
  type: REMOVE_SCROLL_HANDLER,
  payload: { fn },
});

export {
  saveRafHandler,
  removeRafHandler,
  saveResizeHandler,
  removeResizeHandler,
  saveWindowClickHandler,
  removeWindowClickHandler,
  saveScrollHandler,
  removeScrollHandler,
};
