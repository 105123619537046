const REMOVE_SCROLL_HANDLER = 'REMOVE_SCROLL_HANDLER';
const REMOVE_WINDOW_CLICK_HANDLER = 'REMOVE_WINDOW_CLICK_HANDLER';
const REMOVE_RAF_HANDLER = 'REMOVE_RAF_HANDLER';
const REMOVE_RESIZE_HANDLER = 'REMOVE_RESIZE_HANDLER';
const SAVE_SCROLL_HANDLER = 'SAVE_SCROLL_HANDLER';
const SAVE_WINDOW_CLICK_HANDLER = 'SAVE_WINDOW_CLICK_HANDLER';
const SAVE_RAF_HANDLER = 'SAVE_RAF_HANDLER';
const SAVE_RESIZE_HANDLER = 'SAVE_RESIZE_HANDLER';

export {
  REMOVE_SCROLL_HANDLER,
  REMOVE_WINDOW_CLICK_HANDLER,
  REMOVE_RAF_HANDLER,
  REMOVE_RESIZE_HANDLER,
  SAVE_SCROLL_HANDLER,
  SAVE_WINDOW_CLICK_HANDLER,
  SAVE_RAF_HANDLER,
  SAVE_RESIZE_HANDLER,
};
