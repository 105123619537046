import { useRef, useCallback } from 'react';

export default () => {
  const collectionRef = useRef([]);
  const cb = useCallback(
    index => (ref) => {
      collectionRef.current[index] = ref;
    },
    [],
  );

  return [cb, collectionRef];
};
