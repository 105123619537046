import { useRef, useEffect, useContext } from 'react';
import * as PIXI from 'pixi.js-legacy';
import anime from 'animejs';
import { PixiContext } from '@/context/pixi';
import { Context } from '@/context';
import { Context as LayerContext } from '@/context/layer';
import { prepareFilter, loadPixiTexture } from '@/utils';
import { useResize } from '@/package/ReactHandlers';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import fragment from './displacement.glsl';
import dispOverlay from '@/assets/images/disp-overlay.jpg';

export default () => {
  const containerRef = useRef();
  const backgroundRef = useRef();
  const noiseRef = useRef();

  const { isPixiLoaderComplete, pixiResources } = useContext(Context);
  const { isAboutActive, customFilterRef } = useContext(LayerContext);
  const { app } = useContext(PixiContext);

  const fitSprite = (currentSprite) => {
    const size = {
      width: global.innerWidth,
      height: global.innerHeight,
    };
    Object.assign(currentSprite, size);
    currentSprite.anchor.set(0.5, 0.5);
    currentSprite.x = currentSprite.width / 2; // eslint-disable-line no-param-reassign
    currentSprite.y = currentSprite.height / 2; // eslint-disable-line no-param-reassign
  };

  const onResize = () => {
    const background = backgroundRef.current;
    fitSprite(background);
  };

  useResize(() => {
    if (containerRef.current) onResize();
  });

  const loadTexture = async (urls) => {
    await loadPixiTexture({
      src: urls,
      complete: (e) => {
        pixiResources.current = Object.assign(pixiResources.current, e.resources);
      },
    });
  };

  const createContainer = () => {
    containerRef.current = new PIXI.Container();
    backgroundRef.current = new PIXI.Sprite(PIXI.Texture.WHITE);
    backgroundRef.current.tint = 0x000000;
    noiseRef.current = new PIXI.Sprite(pixiResources.current[dispOverlay]?.texture);
  };

  useEffect(() => {
    loadTexture(dispOverlay);
  }, []);

  useUpdateEffect(() => {
    anime({
      targets: customFilterRef.current.uniforms,
      easing: 'easeInOutQuad',
      uAlpha: isAboutActive ? 1 : 0,
      duration: 1500,
      delay: isAboutActive ? 0 : 100,
    });
  }, [isAboutActive]);

  useUpdateEffect(() => {
    createContainer();
    onResize();
    const container = containerRef.current;
    const background = backgroundRef.current;
    const noise = noiseRef.current;

    if (container) {
      container.width = app.screen.width;
      container.height = app.screen.height;

      noise.renderable = false;

      const noiseSize = [
        noise.texture.baseTexture.realWidth,
        noise.texture.baseTexture.realHeight,
      ];

      customFilterRef.current = prepareFilter({
        fragment,
        resolution: global.devicePixelRatio,
        autoFit: false,
        uniforms: {
          uNoise: noise._texture, // eslint-disable-line no-underscore-dangle
          uNoiseSize: noiseSize,
          uAlpha: 0,
          uIntensity: 0.1,
          uDirection: 0.0,
        },
      });

      background.filters = [customFilterRef.current];

      container.addChild(background);
      container.addChild(noise);
      app.stage.addChild(container);
    }
  }, [isPixiLoaderComplete]);

  return null;
};
