import React, { useContext, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';
import Delayed from 'react-delayed';
import { useRaf } from '@/package/ReactHandlers';
import { calculatePan, lerp } from '@/utils';
import { Context } from '@/context';
import { Context as LayerContext } from '@/context/layer';
import { Context as MouseContext } from '@/context/mouse';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import styles from './Menu.module.css';

const Menu = ({ items }) => {
  const wrapperRef = useRef();
  const listWrapperRef = useRef();
  const { isMenuActive } = useContext(LayerContext);
  const { sectionFromMenu, setSectionFromMenu } = useContext(Context);
  const { isPressHold } = useContext(MouseContext);
  const sectionFromMenuRef = useRef(0);
  const pan = useRef(0);
  const enablePan = useRef(false);
  const wrapperHeight = useRef(0);

  useUpdateEffect(() => {
    anime({
      targets: wrapperRef.current,
      opacity: isMenuActive ? 1 : 0,
      easing: 'easeInOutQuad',
    });
    enablePan.current = isMenuActive;
    if (enablePan.current) wrapperHeight.current = parseInt(anime.get(listWrapperRef.current, 'height')) + 500;
  }, [isMenuActive]);

  const onMouseMove = useCallback((e) => {
    if (
      e.target.dataset.index
      && Number(e.target.dataset.index) !== sectionFromMenuRef.current
      && isPressHold
    ) {
      setSectionFromMenu(Number(e.target.dataset.index));
    }
    if (enablePan.current) pan.current = calculatePan(e.pageY, wrapperHeight.current, 200);
  }, [isPressHold]);

  useEffect(() => {
    global.addEventListener('mousemove', onMouseMove);
    global.addEventListener('touchmove', onMouseMove);
    return () => {
      global.removeEventListener('mousemove', onMouseMove);
      global.removeEventListener('touchmove', onMouseMove);
    };
  }, [isPressHold]);

  useRaf(() => {
    if (enablePan.current) {
      const oldY = parseInt(anime.get(listWrapperRef.current, 'translateY'));
      const lerpY = lerp(oldY, pan.current, 0.1);
      anime.set(listWrapperRef.current, { translateY: lerpY });
    }
  }, [pan]);

  useEffect(() => {
    sectionFromMenuRef.current = sectionFromMenu;
  }, [sectionFromMenu]);

  return (
    <Delayed
      mounted={isMenuActive}
      unmountAfter={1000}
    >
      <div
        ref={wrapperRef}
        className={styles.root}
      >
        <div className={styles.list}>
          <div
            ref={listWrapperRef}
            className={styles.listWrapper}
          >
            {items.map((item, i) => (
              <div
                key={i} // eslint-disable-line
                className={classNames({
                  [styles.item]: true,
                  [styles.active]: i === sectionFromMenu,
                })}
                data-index={i}
              >
                <span className={styles.order}>{item.chapterNumber}</span>
                <span
                  data-text={item.title}
                  className={styles.title}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Delayed>
  );
};

Menu.propTypes = { items: PropTypes.array.isRequired };

export default Menu;
