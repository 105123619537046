import { useContext, useRef, useCallback } from 'react';
import anime from 'animejs';
import * as PIXI from 'pixi.js-legacy';
import { isMobile } from 'react-device-detect';
import { WARP_FACTOR } from '@/constants';
import { Context } from '@/context';
import { PixiContext } from '@/context/pixi';
import { Scrollbar } from '@/context/scrollbar';
import { useRaf } from '@/package/ReactHandlers';
import { lerp, modulate } from '@/utils';
import useBreakpoints from '@/hooks/useBreakpoints';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import fragShader from './shader.frag';

const PixiFilters = () => {
  const { renderer, dispContainer, mainContainer } = useContext(PixiContext);
  const { scroll } = useContext(Scrollbar);
  const { isPixiLoaderComplete } = useContext(Context);
  const { screen } = useBreakpoints();
  const dataRef = useRef({
    ease: 0.1,
    current: 0,
    last: 0,
  });
  const timeRef = useRef(0);
  const uniforms = useRef({
    uTime: 0,
    uScroll: 0,
    uTexture: 0,
    uFrequency: 5,
    uGain: 0.01,
    uAmplitude: 0.2,
    uPeriodic: 100,
    uTween: 0,
  });
  const noiseTexture = useRef(undefined);
  const noisePixiContainer = useRef();
  const debugSprite = useRef();
  const dispSprite = useRef();
  const dispFilter = useRef();

  useRaf(() => {
    dataRef.current.current = scroll.current;
    dataRef.current.last = lerp(dataRef.current.last, dataRef.current.current, dataRef.current.ease);
    dataRef.current.last = Math.floor(dataRef.current.last * 100) / 100;
    const diff = dataRef.current.current - dataRef.current.last;
    const acc = diff / global.innerWidth;
    const velo = +acc;
    timeRef.current += 0.01;
    const animation = Math.abs(velo * WARP_FACTOR[screen]);
    const scrollFactor = modulate(animation, [0, 16], [0, 1.8], true);

    uniforms.current.uScroll = scrollFactor;
    uniforms.current.uTime = timeRef.current;
    uniforms.current.uTexture = noiseTexture.current;
    if (noiseTexture.current && noisePixiContainer.current) renderer.render(noisePixiContainer.current, noiseTexture.current, true);
  }, [screen]);

  const createNoiseFilter = useCallback(() => {
    noisePixiContainer.current = new PIXI.Sprite();
    noisePixiContainer.current.name = 'NOISE CONTAINER';
    noisePixiContainer.current.width = 100;
    noisePixiContainer.current.height = 100;

    const noise = new PIXI.Filter(undefined, fragShader, uniforms.current);

    noisePixiContainer.current.filters = [noise];

    noiseTexture.current = PIXI.RenderTexture.create(100, 100);

    debugSprite.current = PIXI.Sprite.from(noiseTexture.current);
    debugSprite.current.width = global.innerWidth;
    debugSprite.current.height = global.innerHeight;
    debugSprite.current.alpha = 0.8;
    // app.stage.addChild(debugSprite.current);
  });

  const createDisplacementFilter = useCallback(() => {
    dispSprite.current = PIXI.Sprite.from(noiseTexture.current);
    dispSprite.current.name = 'DISPLACEMENT';
    dispSprite.current.width = global.innerWidth;
    dispSprite.current.height = global.innerHeight;
    dispContainer.addChild(dispSprite.current);

    dispFilter.current = new PIXI.filters.DisplacementFilter(dispSprite.current, 20);
    dispFilter.current.padding = 50;
    dispFilter.current.autoFit = true;

    if (!isMobile) mainContainer.filters = [dispFilter.current];
  });

  const createEffects = useCallback(() => {
    anime.set(uniforms.current, { uTween: 1 });
    createNoiseFilter();
    createDisplacementFilter();
  });

  useUpdateEffect(() => {
    createEffects();
  }, [isPixiLoaderComplete]);

  return null;
};

export default PixiFilters;
