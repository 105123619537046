import { useContext, useRef, useCallback, useEffect } from 'react';
import * as PIXI from 'pixi.js-legacy';
import anime from 'animejs';
import { isMobile } from 'react-device-detect';
import { useResize } from '@/package/ReactHandlers';
import { Context } from '@/context';
import { Context as LayerContext } from '@/context/layer';
import { Context as MouseContext } from '@/context/mouse';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import useBreakpoints from '@/hooks/useBreakpoints';
import useScrollbar from '@/hooks/useScrollbar';
import { getObjectFitData } from '@/utils';
import { IMAGE_WIDTH, SIZES, SIZE_ORDER, GUTTER } from '@/constants';

const PixiSprite = ({
  container,
  texture,
  isReady,
  index,
  layout,
}) => {
  const containerSpriteRef = useRef();
  const spriteRef = useRef();
  const { pixiResources, setCurrentGalleryThumb } = useContext(Context);
  const { activeLayer, isMenuActive, isGalleryActive } = useContext(LayerContext);
  const { isPressHold, isSpriteOver } = useContext(MouseContext);
  const { screen } = useBreakpoints();
  const touchPosition = useRef();
  const isTap = useRef(true);
  const oldScale = useRef(0);
  const isPositioned = useRef(false);
  const bounds = useRef({ minY: 0, maxY: 0 });

  const updateBounds = useCallback(() => {
    Object.assign(bounds.current, {
      minY: containerSpriteRef.current?._bounds?.minY, // eslint-disable-line no-underscore-dangle
      maxY: containerSpriteRef.current?._bounds?.maxY, // eslint-disable-line no-underscore-dangle
    });
  });

  useUpdateEffect(() => {
    updateBounds();
  }, [isPositioned.current]);

  const textureRealSize = (currentSprite) => {
    const { baseTexture } = currentSprite.texture;
    return {
      width: baseTexture.realWidth,
      height: baseTexture.realHeight,
    };
  };

  const fitMediaToParent = (currentSprite, scale, mq) => {
    const size = textureRealSize(currentSprite);
    const textureObjectFitData = getObjectFitData(
      IMAGE_WIDTH[mq].width * (!isMobile ? scale : mq === 'md' ? 3 : 1), // eslint-disable-line
      IMAGE_WIDTH[mq].height * (!isMobile ? scale : mq === 'md' ? 3 : 1), // eslint-disable-line
      size.width,
      size.height,
    );
    // Fit Image
    Object.assign(currentSprite, textureObjectFitData);
    currentSprite.anchor.set(0.5, 0.5);
  };

  useScrollbar(({ y }) => { // eslint-disable-line
    // if (isMobile && containerSpriteRef.current) {
    //   containerSpriteRef.current.renderable = (
    //     (y < bounds.current.minY && (y + global.innerHeight) > bounds.current.minY)
    //     || (y < bounds.current.maxY && (y + global.innerHeight) > bounds.current.maxY)
    //   );
    // }
  });

  const setSize = () => {
    fitMediaToParent(spriteRef.current, SIZES[SIZE_ORDER[layout][index]], screen);
  };

  const setPosition = (w) => {
    const halfWidth = w / 2;
    const { width, height } = spriteRef.current;
    const halfHeightSprite = height / 2;
    oldScale.current = spriteRef.current.scale.x;

    const left = halfWidth - (width / 2) - GUTTER[screen];
    const right = halfWidth + (spriteRef.current.width / 2) + GUTTER[screen];
    const center = halfWidth;

    if (index === 0) {
      if (layout === 1) {
        containerSpriteRef.current.y = halfHeightSprite;
        containerSpriteRef.current.x = left;
      }
      if (layout === 2) {
        containerSpriteRef.current.y = halfHeightSprite;
        containerSpriteRef.current.x = center;
      }
      if (layout === 3) {
        containerSpriteRef.current.y = halfHeightSprite;
        containerSpriteRef.current.x = left;
      }
      if (layout === 4) {
        containerSpriteRef.current.y = halfHeightSprite;
        containerSpriteRef.current.x = left;
      }
    }
    if (index === 1) {
      if (layout === 1) {
        containerSpriteRef.current.y = height;
        containerSpriteRef.current.x = right;
      }
      if (layout === 2) {
        containerSpriteRef.current.y = (height * 2) - (halfHeightSprite / 2) + GUTTER[screen];
        containerSpriteRef.current.x = right;
      }
      if (layout === 3) {
        containerSpriteRef.current.y = height + (halfHeightSprite / 2);
        containerSpriteRef.current.x = right;
      }
      if (layout === 4) {
        containerSpriteRef.current.y = height + (halfHeightSprite / 2);
        containerSpriteRef.current.x = right;
      }
    }
    if (index === 2) {
      if (layout === 1) {
        containerSpriteRef.current.y = height + halfHeightSprite;
        containerSpriteRef.current.x = left;
      }
      if (layout === 2) {
        containerSpriteRef.current.y = height * 3;
        containerSpriteRef.current.x = left;
      }
      if (layout === 3) {
        containerSpriteRef.current.y = (height * 2) + halfHeightSprite;
        containerSpriteRef.current.x = left;
      }
      if (layout === 4) {
        containerSpriteRef.current.y = height * 2;
        containerSpriteRef.current.x = center;
      }
    }
    if (index === 3) {
      if (layout === 1) {
        containerSpriteRef.current.y = (height * 2) + (halfHeightSprite / 2);
        containerSpriteRef.current.x = center;
      }
      if (layout === 2) {
        containerSpriteRef.current.y = (height * 6);
        containerSpriteRef.current.x = center;
      }
      if (layout === 3) {
        containerSpriteRef.current.y = (height * 3) + halfHeightSprite;
        containerSpriteRef.current.x = right;
      }
      if (layout === 4) {
        containerSpriteRef.current.y = (height * 6) + halfHeightSprite;
        containerSpriteRef.current.x = right;
      }
    }
    if (isMobile) {
      containerSpriteRef.current.y = (height * index) + halfHeightSprite + (GUTTER[screen] * index);
      containerSpriteRef.current.x = halfWidth;
    }
    isPositioned.current = true;
  };

  const createSprite = () => {
    containerSpriteRef.current = new PIXI.Container();
    const resourceTexture = pixiResources.current[texture.url]?.texture;
    spriteRef.current = new PIXI.Sprite(resourceTexture);
    spriteRef.current.interactive = true;
    spriteRef.current.cursor = 'pointer';

    containerSpriteRef.current.addChild(spriteRef.current);
    container.current.addChild(containerSpriteRef.current);
  };

  useUpdateEffect(() => {
    createSprite();
    setSize();
    setPosition(global.innerWidth);
  }, [isReady]);

  useResize(() => {
    if (container.current) {
      setSize();
      setPosition(global.innerWidth);
    }
  }, [screen]);

  const onClick = useCallback((e) => {
    if (isTap.current && !isMobile) {
      setCurrentGalleryThumb(e.currentTarget._texture.textureCacheIds[0]); // eslint-disable-line no-underscore-dangle
      activeLayer('gallery');
    }
  }, [isPressHold]);

  const onTouch = useCallback((e) => {
    isTap.current = Math.abs(e.data.global.y - touchPosition.current) < 10;
    touchPosition.current = e.data.global.y;
  });

  const onMouseEnter = useCallback(() => {
    isSpriteOver.current = true;
  });

  const onMouseLeave = useCallback(() => {
    isSpriteOver.current = false;
  });

  useUpdateEffect(() => {
    const scale = (oldScale.current / 100) * 90;
    anime.remove(spriteRef.current.scale);
    anime({
      targets: spriteRef.current.scale,
      x: isMenuActive ? scale : oldScale.current,
      y: isMenuActive ? scale : oldScale.current,
      easing: 'easeInOutQuad',
    });
  }, [isMenuActive]);

  useUpdateEffect(() => {
    const scale = (oldScale.current / 100) * 80;
    anime.remove(spriteRef.current.scale);
    anime({
      targets: spriteRef.current.scale,
      x: isGalleryActive ? scale : oldScale.current,
      y: isGalleryActive ? scale : oldScale.current,
      easing: 'easeInOutQuad',
    });
  }, [isGalleryActive]);

  useEffect(() => {
    if (!spriteRef.current) return;
    spriteRef.current.on('click', onClick);
    spriteRef.current.on('touchstart', onTouch);
    spriteRef.current.on('touchend', onTouch);
    spriteRef.current.on('tap', onClick);
    spriteRef.current.on('mouseover', onMouseEnter);
    spriteRef.current.on('mouseout', onMouseLeave);

    return () => {
      spriteRef.current.off('click', onClick);
      spriteRef.current.off('touchstart', onTouch);
      spriteRef.current.off('touchend', onTouch);
      spriteRef.current.off('tap', onClick);
      spriteRef.current.off('mouseover', onMouseEnter);
      spriteRef.current.off('mouseout', onMouseLeave);
    };
  }, [spriteRef.current]);

  return null;
};

PixiSprite.defaultProps = { layout: 1 };

export default PixiSprite;
