import React from 'react';
import Layout from '@/layout/layout';
import Title from '@/components/Title';
import Gallery from '@/components/Gallery';

const IndexPage = () => (
  <Layout>
    <Title />
    <Gallery />
  </Layout>
);

export default IndexPage;
