import React, { useContext, useRef } from 'react';
import anime from 'animejs';
import { Context as LayerContext } from '@/context/layer';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import styles from './PlainOverlay.module.css';

const PlainOverlay = () => {
  const rootRef = useRef();
  const { isGalleryActive } = useContext(LayerContext);

  useUpdateEffect(() => {
    anime({
      targets: rootRef.current,
      opacity: isGalleryActive ? 1 : 0,
      easing: 'easeInOutQuad',
    });
  }, [isGalleryActive]);

  return (
    <div
      ref={rootRef}
      className={styles.root}
    />
  );
};

export default PlainOverlay;
