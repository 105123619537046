import React, { useContext, useRef, useCallback, useState, useEffect } from 'react';
import charming from 'charming';
import anime from 'animejs';
import { Context } from '@/context';
import { Context as LayerContext } from '@/context/layer';
import useUpdateEffect from '@/hooks/useUpdateEffect';
import styles from './Title.module.css';

const Title = () => {
  const { comic, currentSection, language } = useContext(Context);
  const { isLayerActive } = useContext(LayerContext);
  const [isTextOut, setTextOut] = useState(false);
  const titlesRef = useRef([]);
  const headingRef = useRef();
  const rootRef = useRef();
  const lettersRef = useRef([]);

  const splitTitle = useCallback(() => {
    charming(headingRef.current, { classPrefix: 'letter' });
    lettersRef.current = [].slice.call(headingRef.current.querySelectorAll('span'));
  }, []);

  const changeText = () => {
    anime.timeline()
      .add({
        targets: lettersRef.current,
        duration: 250,
        delay: (el, index) => index * 25,
        easing: 'easeOutCubic',
        translateY: '100%',
      })
      .add({
        targets: headingRef.current,
        translateY: '100%',
        duration: 50,
        complete: () => {
          headingRef.current.innerHTML = titlesRef.current[currentSection];
          splitTitle();
          anime.set(headingRef.current, { translateY: '0' });
          anime.set(lettersRef.current, { translateY: '100%' });
          setTextOut(true);
        },
      });
  };

  const animateIn = useCallback(() => {
    anime.remove(lettersRef.current);
    anime.timeline()
      .add({
        targets: lettersRef.current,
        duration: 250,
        delay: (el, index) => 200 + (index * 25),
        easing: 'easeOutCubic',
        translateY: ['100%', '0%'],
        complete: () => setTextOut(false),
      });
  }, []);

  useEffect(() => {
    comic.map(item => titlesRef.current.push(item.node[language === 'it' ? 'title' : 'titleEn']));
    splitTitle();
  }, []);

  useUpdateEffect(() => {
    anime.remove(rootRef.current);
    anime({
      targets: rootRef.current,
      opacity: isLayerActive ? 0 : 1,
      easing: 'easeInOutQuad',
    });
  }, [isLayerActive]);

  useEffect(() => {
    if (currentSection >= 0) changeText();
  }, [currentSection]);

  useUpdateEffect(() => {
    if (isTextOut) animateIn();
  }, [isTextOut]);

  return (
    <div className={styles.root}>
      <div
        ref={rootRef}
        className={styles.slider}
      >
        <h1 ref={headingRef}>Tanos Hobos</h1>
      </div>
    </div>
  );
};

export default Title;
