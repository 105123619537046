import {
  useEffect,
  useState,
} from 'react';

const useDebouncedBoolean = (props = [], delay = 1000) => {
  const [debouncedValue, setDebouncedValue] = useState(false);

  useEffect(() => {
    setDebouncedValue(true);
    setTimeout(() => setDebouncedValue(false), delay);
  }, props);

  return debouncedValue;
};

export default useDebouncedBoolean;
