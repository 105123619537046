/* global window, cancelAnimationFrame, requestAnimationFrame  */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from '@/package/ReactHandlers/context'; // eslint-disable-line
import { reducer, actions } from '@/package/ReactHandlers/state';

export default class Handlers extends Component {
  static propTypes = {
    debounce: PropTypes.number,
    mountTicker: PropTypes.func,
    unmountTicker: PropTypes.func,
  }

  static defaultProps = {
    debounce: 300,
    mountTicker: undefined,
    unmountTicker: undefined,
  }

  state = Object.assign({}, reducer(), actions, {
    dispatch: (action) => {
      this.setState((state) => {
        const newState = reducer(state, action);
        return newState;
      });
    },
  })

  resizeTimeout = undefined

  rafId = undefined

  componentDidMount() {
    const { mountTicker } = this.props;
    if (mountTicker) {
      mountTicker(this.onRaf);
    } else {
      this.rafId = requestAnimationFrame(this.onRaf);
    }
    window.addEventListener('resize', this.onResize, false);
    window.addEventListener('click', this.onWindowClick, false);
    window.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    const { unmountTicker } = this.props;
    if (unmountTicker) {
      unmountTicker(this.onRaf);
    } else {
      cancelAnimationFrame(this.rafId);
    }
    window.removeEventListener('resize', this.onResize, false);
    window.removeEventListener('click', this.onWindowClick, false);
    window.removeEventListener('scroll', this.onScroll, false);
  }

  onWindowClick = (e) => {
    const { windowClick } = this.state;
    windowClick.forEach(h => h(e));
  }

  onRaf = (event) => {
    const { raf } = this.state;
    raf.forEach(h => h(event));
    const { mountTicker } = this.props;
    if (!mountTicker) {
      this.rafId = requestAnimationFrame(this.onRaf);
    }
  }

  onResize = () => {
    const { resize } = this.state;
    const { debounce } = this.props;
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      resize.forEach(h => h(width, height));
    }, debounce);
  }

  onScroll = () => {
    const { scroll } = this.state;
    scroll.forEach(h => h({
      x: window.pageXOffset,
      y: window.pageYOffset,
    }));
  }

  render() {
    const { children } = this.props;
    return (
      <Provider value={this.state}>
        {children}
      </Provider>
    );
  }
}
