import React, { createContext, useRef } from 'react';

const PixiContext = createContext();

const Provider = ({ children }) => {
  const ref = useRef({
    app: null,
    stage: null,
    container: null,
  });

  const domElement = useRef();

  return (
    <PixiContext.Provider
      value={{
        ...ref.current,
        domElement,
      }}
    >
      {children}
    </PixiContext.Provider>
  );
};

export { PixiContext };

export default Provider;
