import React, { useRef, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import useMouse from '@/hooks/useMouse';
import { useRaf } from '@/package/ReactHandlers';
import { modulate } from '@/utils';
import { Context } from '@/context';
import { Context as MouseContext } from '@/context/mouse';
import { Context as LayerContext } from '@/context/layer';
import Arrow from '@/assets/svg-inline/arrow.svg';
import Close from '@/assets/svg-inline/close.svg';
import styles from './Cursor.module.css';

const Cursor = ({
  color,
  colorSmall,
  size,
  sizeSmall,
  strokeWidth,
}) => {
  const cursorRef = useRef();
  const cursorOuterRef = useRef();
  const ellipseRef = useRef();
  const radius = (size - strokeWidth) / 2;
  const dashArray = radius * (Math.PI * 2);
  const cursorInnerRef = useRef();
  const controlRef = useRef();
  const closeRef = useRef();
  const { galleryDirection, counterPress } = useContext(MouseContext);
  const { closeGallery } = useContext(Context);
  const { isGalleryActive } = useContext(LayerContext);

  useRaf(() => {
    const offset = modulate(counterPress.current, [0, 30], [dashArray, 0]);
    const scale = modulate(counterPress.current, [0, 30], [1, 2]);
    if (ellipseRef.current) ellipseRef.current.style.strokeDashoffset = offset;
    if (cursorRef.current) cursorRef.current.style.transform = `scale(${scale})`;
  }, [counterPress]);

  useMouse(({ easeX, easeY, x, y }) => {
    if (cursorOuterRef.current) {
      Object.assign(cursorOuterRef.current.style, {
        top: `${easeY}px`,
        left: `${easeX}px`,
      });
    }
    if (controlRef.current) {
      Object.assign(controlRef.current.style, {
        top: `${easeY}px`,
        left: `${easeX}px`,
      });
    }
    if (closeRef.current) {
      Object.assign(closeRef.current.style, {
        top: `${easeY}px`,
        left: `${easeX}px`,
      });
    }
    if (cursorInnerRef.current) {
      Object.assign(cursorInnerRef.current.style, {
        top: `${y}px`,
        left: `${x}px`,
      });
    }
  });

  const renderClose = useMemo(() => (
    <div
      ref={closeRef}
      className={classNames({
        [styles.container]: true,
        [styles.close]: true,
        [styles.uiButton]: true,
        [styles.visible]: isGalleryActive && closeGallery,
      })}
    >
      <Close />
    </div>
  ));

  const renderControl = useMemo(() => (
    <div
      ref={controlRef}
      className={classNames({
        [styles.container]: true,
        [styles.uiButton]: true,
        [styles.visible]: isGalleryActive && !closeGallery,
      })}
      style={{ marginTop: '-10px', marginLeft: '-20px' }}
      data-arrow
    >
      <div className={styles.wrapper}>
        <Arrow
          className={classNames({
            [styles.arrow]: true,
            [styles.prev]: galleryDirection === 'prev',
          })}
        />
      </div>
    </div>
  ));

  const renderCursorOuter = useMemo(() => (
    <div
      ref={cursorOuterRef}
      className={classNames({
        [styles.container]: true,
        [styles.uiButton]: true,
        [styles.visible]: !isGalleryActive,
      })}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        marginTop: `-${size / 2}px`,
        marginLeft: `-${size / 2}px`,
      }}
    >
      <div
        className={styles.wrapper}
        style={{
          width: `${size}px`,
          height: `${size}px`,
        }}
      >
        <svg
          height={size}
          width={size}
          viewBox={`0 0 ${size} ${size}`}
        >
          <circle
            id="circle_big"
            cx={size / 2}
            cy={size / 2}
            r={radius}
            fill={color}
          />
          <circle
            ref={ellipseRef}
            className={styles.circleprogress}
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            transform={`rotate(180 ${size / 2} ${size / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashArray,
            }}
          />
        </svg>
      </div>
    </div>
  ));

  const renderCursorInner = useMemo(() => (
    <div
      ref={cursorInnerRef}
      className={classNames({
        [styles.container]: true,
        [styles.uiButton]: true,
        [styles.visible]: !isGalleryActive,
      })}
      style={{
        width: `${sizeSmall}px`,
        height: `${sizeSmall}px`,
        marginTop: `-${sizeSmall / 2}px`,
        marginLeft: `-${sizeSmall / 2}px`,
      }}
    >
      <div
        ref={cursorRef}
        className={styles.wrapper}
        style={{
          width: `${sizeSmall}px`,
          height: `${sizeSmall}px`,
        }}
      >
        <svg
          height={sizeSmall}
          width={sizeSmall}
          viewBox={`0 0 ${sizeSmall} ${sizeSmall}`}
        >
          <circle
            id="circle_small"
            cx={sizeSmall / 2}
            cy={sizeSmall / 2}
            r={sizeSmall / 2}
            fill={colorSmall}
          />
        </svg>
      </div>
    </div>
  ));

  return !isMobile ? (
    <div>
      {renderClose}
      {renderControl}
      {renderCursorOuter}
      {renderCursorInner}
    </div>
  ) : null;
};

Cursor.defaultProps = {
  color: 'transparent',
  colorSmall: '#ffffff',
  size: 100,
  sizeSmall: 8,
  strokeWidth: 3,
};

Cursor.propTypes = {
  color: PropTypes.string,
  colorSmall: PropTypes.string,
  size: PropTypes.number,
  sizeSmall: PropTypes.number,
  strokeWidth: PropTypes.number,
};

export default Cursor;
