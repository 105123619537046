import { useContext } from 'react';
import { Scrollbar } from '@/context/scrollbar';
import { useRaf } from '@/package/ReactHandlers';

export default (cb, props) => {
  const scrollbar = useContext(Scrollbar);
  useRaf(() => {
    const { scroll: { current }, delta } = scrollbar;
    const y = Math.abs(current);
    cb({
      y,
      delta: delta.current,
    });
  }, props);
};
