import React, { useRef, useCallback, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';
import { useResize } from '@/package/ReactHandlers';
import usePrevious from '@/hooks/usePrevious';
import { prevIndex, nextIndex, directionByIndex } from '@/utils';

const Slider = ({
  direction,
  mask,
  vCenter,
  hCenter,
  loop,
  loopedSlides,
  index,
  time,
  onResize,
  easing,
  className,
  activeClassName,
  children,
  autofit,
}) => {
  const wrapperRef = useRef();
  const slideRef = useRef();
  const slidesRef = useRef();
  const isAnimating = useRef(false);
  const size = useRef({ width: 0, height: 0 });
  const previousIndex = usePrevious(index);

  const getTransformStyle = useCallback((axis, value) => `translate${axis.toUpperCase()}(${value}px)`, []);

  const axis = direction === 'horizontal' ? 'left' : 'top';

  const transformAxis = direction === 'horizontal' ? 'translateX' : 'translateY';

  const side = direction === 'horizontal' ? 'width' : 'height';

  const getLoopIndex = useMemo(() => {
    const { length } = children;
    if (!loop) return Array.from(Array(length).keys());

    const slides = [index];
    let lastPrevIndex = index;
    let lastNextIndex = index;

    [...Array(loopedSlides).keys()].forEach(() => {
      lastPrevIndex = prevIndex(lastPrevIndex, length);
      lastNextIndex = nextIndex(lastNextIndex, length);
      slides.unshift(lastPrevIndex);
      slides.push(lastNextIndex);
    });
    return slides;
  }, [index]);

  const getChildren = useMemo(() => getLoopIndex.map(i => children[i]), [getLoopIndex]);

  const goToSlide = useCallback((prev, duration = time) => {
    const { length } = children;
    const dir = directionByIndex(prev, index, length);

    if (loop) {
      anime({
        begin: () => isAnimating.current = true,
        targets: slideRef.current,
        duration,
        easing,
        [transformAxis]: [`${dir === 'next' ? '+' : '-'}${size.current[side]}`, 0],
        complete: () => isAnimating.current = false,
      });
    } else if (duration === 0) {
      slideRef.current.style.transform = getTransformStyle(transformAxis, -(index * size.current[side])); // eslint-disable-line
    }
  }, [index]);

  const handleResize = useCallback(() => {
    if (!slideRef.current || !wrapperRef.current) return;
    slidesRef.current = Array.from(slideRef.current.children);

    /**
     * Reset slide
     */
    anime.remove([wrapperRef.current, slideRef.current]);

    if (autofit) {
      size.current.width = wrapperRef.current.parentNode.clientWidth;
      size.current.height = wrapperRef.current.parentNode.clientHeight;
    } else {
      size.current.width = slideRef.current.clientWidth;
      size.current.height = Math.max(
        ...slidesRef.current.map(s => (
          s.clientHeight || (s.children[0] ? s.children[0].clientHeight : 0)
        )),
      );
    }

    const { width, height } = size.current;

    /**
     * Set size to wrapper
     */
    Object.assign(wrapperRef.current.style, {
      position: 'relative',
      width: `${width}px`,
      height: `${height}px`,
      overflow: mask ? 'hidden' : 'unset',
    });

    /**
     * Place slide to default position
     */
    slidesRef.current.forEach((s, i) => {
      const prop = {
        position: 'absolute',
        width: `${width}px`,
        height: `${height}px`,
        [axis]: `${(i - (loop ? loopedSlides : 0)) * size.current[side]}px`,
      };
      if (hCenter || vCenter) prop.display = 'flex';
      if (hCenter) prop.justifyContent = 'center';
      if (vCenter) prop.alignItems = 'center';
      Object.assign(s.style, prop);
    });

    goToSlide(null, 0);
  }, []);

  useResize(() => {
    if (onResize) handleResize();
  });

  useLayoutEffect(() => {
    if (previousIndex === null) return;
    if (previousIndex !== index) goToSlide(previousIndex);
  }, [index]);

  return (
    <div
      ref={wrapperRef}
      className={className}
    >
      <div ref={slideRef}>
        {getChildren.map((c, i) => (
          <div
            key={i} // eslint-disable-line
            className={classNames({ [activeClassName]: activeClassName && getLoopIndex[i] === index })}
          >
            {c}
          </div>
        ))}
      </div>
    </div>
  );
};

Slider.defaultProps = {
  autofit: true,
  direction: 'horizontal',
  index: 0,
  time: 500,
  mask: false,
  className: null,
  activeClassName: null,
  vCenter: false,
  hCenter: false,
  loopedSlides: 1,
  loop: true,
  onResize: true,
  easing: 'easeInOutQuad',
};

Slider.propTypes = {
  autofit: PropTypes.bool,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  mask: PropTypes.bool,
  vCenter: PropTypes.bool,
  hCenter: PropTypes.bool,
  loop: PropTypes.bool,
  loopedSlides: PropTypes.number,
  index: PropTypes.number,
  time: PropTypes.number,
  onResize: PropTypes.bool,
  easing: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
};

export default Slider;
