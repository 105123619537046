import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { omit } from '@/utils';
import styles from './Cta.module.css';

const Cta = forwardRef(({
  className,
  text,
  color,
  underline,
  onClick,
  ...props
}, ref) => (
  <props.component
    ref={ref}
    onClick={onClick}
    className={classNames({
      [styles.wrapper]: true,
      [styles.underline]: underline,
      [styles.hover]: isMobile,
      [className]: className,
    })}
    data-button
    {...omit(props, [
      'component',
    ])}
  >
    {text}
  </props.component>
));

Cta.propTypes = {
  component: PropTypes.string,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  underline: PropTypes.bool,
  onClick: PropTypes.func,
};

Cta.defaultProps = {
  component: 'button',
  color: null,
  className: null,
  underline: false,
  onClick: undefined,
};

export default Cta;
